import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import DashboardKpi from "./Dashboard/DashboardKpi";
import kpiModel from "models/kpi.model";
import { helper } from 'lib/helper';
import { useEffect, useState } from "react";
const Header = () => {
  const [driverCount, setDriverCount] = useState(0);
  const [supportCount, setSupportCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const getDashboardKpi = async () => {
    await kpiModel.dashboardKpi().then((result) => {
      setDriverCount(result?.data?.driverCount);
      setSupportCount(result?.data?.supportCount)
      setUserCount(result?.data?.userCount);
    }).catch((error) => {
      console.log(error)
    })
  }
  const totalRides = helper.generateRandomNumber();

  useEffect(() => {
    getDashboardKpi();
  }, [])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <DashboardKpi
                kpiTitle="Total rides"
                kpiValue={totalRides}
                kpiIcon="fas fa-route"
                iconBakgroundColor = "bg-success"
              />
              <DashboardKpi
                kpiTitle="Today's rides"
                kpiValue={helper.generateRandomNumber(totalRides)}
                kpiIcon="fas fa-route"
                iconBakgroundColor = "bg-primary"
              />
              <DashboardKpi
                kpiTitle="Drivers"
                kpiValue={driverCount}
                kpiIcon="fas fa-user-astronaut"
                iconBakgroundColor = "bg-warning"
                navigateUrl = "/admin/drivers"
              />
              <DashboardKpi
                kpiTitle="Users"
                kpiValue={userCount}
                kpiIcon="fas fa-users"
                iconBakgroundColor = "bg-info"
                navigateUrl = "/admin/users"
              />
            </Row>
            <Row className="mt-2">
              <DashboardKpi
                kpiTitle="Message"
                kpiValue={supportCount}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor = "bg-yellow"
                navigateUrl = "/admin/messages"
              />
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
