import axiosInstance from "../lib/axiosInstance";

const FareModel = class {
    constructor() {
        this.baseUrl = "/fare";
        this.stateBaseURL = "/state";
        this.cityBaseURL = "/city"
    }

    async list(query = []) {
        return (await axiosInstance.get(`${this.baseUrl}?${new URLSearchParams(query)}`)).data;
    }

   

    async create(data) {
        console.log(data, "classmodal");
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const jsonData = JSON.stringify(data);
            const response = await axiosInstance.post(this.baseUrl, jsonData, {headers});
            return response.data;
        } catch (error) {
            console.error("Error creating class:", error);
            throw error; // Rethrow the error for handling at the calling site
        }
      }
      

    async detail(id) {
        return (await axiosInstance.get(`${this.baseUrl}/${id}`)).data;
    }
    async update(id, data = []) {
        return await axiosInstance.put(`${this.baseUrl}/${id}`, data);
    }

    async delete(id) {
        return await axiosInstance.delete(`${this.baseUrl}/${id}`);
    }

    async getStates(query = []) {
        return (await axiosInstance.get(`${this.stateBaseURL}?${new URLSearchParams(query)}`)).data;
    }

    async getCity(query = []) {
        return (await axiosInstance.get(`${this.cityBaseURL}?${new URLSearchParams(query)}`)).data;
    }
}

export default new FareModel();