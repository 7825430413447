import { helper } from "lib/helper";
import FareModel from "models/fare.model";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import AddModal from "views/components/AddModal";
// core components
import TablePagination from "views/components/TablePagination";
import EditModal from "views/components/EditModal";

const FareManagement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [dataStates, setDataStatesList] = useState([]);
  const [dataCity, setDataCityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editList, setEditList] = useState(null);
  // const toggleModal = (modalName) => {
  //   setModal(!modal);
  //   getStatesData();
  //   getCityData()
  // };
  const toggleEditModal = (modalName) => {
    setEditModal(!editModal);
    getStatesData();
    getCityData();
  };

  const [formdata, setFormData] = useState({
    state_id: "",
    city_id: "",
    name: "",
    baseKm: "",
    baseKmFare: "",
    perKmFare: "",
    vehicleType: "",
  });

  const [formEditdata, setFormEditData] = useState({
    state_id: editList?.state_id?.name,
    city_id: editList?.city_id?.name,
    name: editList?.name,
    baseKm: editList?.baseKm,
    baseKmFare: editList?.baseKmFare,
    perKmFare: editList?.perKmFare,
    vehicleType: editList?.vehicleType,
  });

  // const handleChangeFormdata = (event) => {
  //   setFormData({ ...formdata, [event.target.name]: event.target.value });
  // };

  const handleChangeEditFormdata = (event) => {
    setFormEditData({
      ...formEditdata,
      [event.target.name]: event.target.value,
    });
  };

 

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const {
      state_id,
      baseKm,
      baseKmFare,
      city_id,
      name,
      perKmFare,
      vehicleType,
    } = formEditdata;
    const fareData = new FormData();
    fareData.append("state_id", state_id);
    fareData.append("city_id", city_id);
    fareData.append("baseKm", baseKm);
    fareData.append("baseKmFare", baseKmFare);
    fareData.append("name", name);
    fareData.append("perKmFare", perKmFare);
    fareData.append("vehicleType", vehicleType);
    await FareModel.create(fareData)
      .then((result) => {
        setEditModal(false);
        helper.sweetalert.toast("Updated Data Successfully");
        setIsLoading(false);
        getListData();
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  const getListData = async () => {
    setIsLoading(true);
    await FareModel.list(parameters)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setDataList(result);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  const getStatesData = async () => {
    setIsLoading(true);
    await FareModel.getStates(parameters)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setDataStatesList(result);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  const getCityData = async () => {
    setIsLoading(true);
    await FareModel.getCity(parameters)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setDataCityList(result);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  const handleDel = async (delId) => {
    helper.sweetalert
      .confirm("Are you sure you want to delete this Message", "info", true)
      .then((result) => {
        if (result.isConfirmed) {
          FareModel.delete(delId).then((res) => {
            helper.sweetalert.toast(res.data?.message);
            getListData();
          });
        }
      });
  };

  const getDetails = async (delId) => {
    FareModel.detail(delId).then((res) => {
      setEditList(res.data);
      getListData();
    });
  };

  const handleEdit = async (editId) => {
    getDetails(editId);
    setEditModal(!editModal);
  };

  useEffect(() => {
    getListData();
  }, [location]);

  useEffect(() => {
    if (editList) {
      setFormEditData({
        state_id: editList.state_id?._id || "",
        city_id: editList.city_id?._id || "",
        name: editList.name || "",
        baseKm: editList.baseKm || "",
        baseKmFare: editList.baseKmFare || "",
        perKmFare: editList.perKmFare || "",
        vehicleType: editList.vehicleType || "",
      });
    }
  }, [editList]);

  const [modal1, setModal1] = useState(false);
  const toggleModal = () => {
    setModal1(!modal1);
    getStatesData();
    getCityData();
  };

  const [selectedDriver, setSelectedDriver] = useState("");

  const handleSelectChange = (e) => {
    setSelectedDriver(e.target.value);
  };

  const [vehicleType, setVehicleType] = useState("");

    const initialState={
    state_id: "",
    city_id: "",
    name: "",
    baseKm: "",
    baseKmFare: "",
    vehicleType: vehicleType && vehicleType,
    perKmFare: "",
    borewellFareData: [{ drillRange: "", baseRate: "" }],
  }
  const [formdatanew, setFormdatanew] = useState(initialState)

  const handleVehicleTypeChange = (e) => {
    const selectedVehicleType = e.target.value;
    setVehicleType(selectedVehicleType);  // Update the local state
    setFormdatanew((prevState) => ({
      ...prevState,
      vehicleType: selectedVehicleType,  // Update the vehicleType in formdatanew
    }));
  };

  const handleChangeFormdata = (e) => {
    setFormdatanew({
      ...formdatanew,
      [e.target.name]: e.target.value,
    });
  };

  const addDrillRange = () => {
    setFormdatanew({
      ...formdatanew,
      borewellFareData: [...formdatanew.borewellFareData, { range: "", baseRate: "" }],
    });
  };

  const handleDrillRangeChange = (index, e) => {
    const updatedDrillRanges = formdatanew.borewellFareData.map((drill, i) =>
      i === index ? { ...drill, [e.target.name]: e.target.value } : drill
    );
    setFormdatanew({
      ...formdatanew,
      borewellFareData: updatedDrillRanges,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    await FareModel.create(formdatanew)
      .then((result) => {
        setModal(false);
        setFormdatanew(initialState)
        helper.sweetalert.toast("Add Data Successfully");
        setIsLoading(false);
        getListData();
       toggleModal()
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  return (
    <>
      <Container className="pt-7">
        {!isLoading && (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex justify-content-between ">
                  <h3 className="mb-0">Fare Management</h3>
                  <div className="d-flex flex-row">
                    <Input className="mr-4" type="text" placeholder="Search" />
                    <Button className="px-3" onClick={toggleModal} size="sm">
                      +Add
                    </Button>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">State</th>
                      <th scope="col">City</th>
                      <th scope="col">Fare Name</th>
                      <th scope="col">Base KM</th>
                      <th scope="col">Base KM Fare</th>
                      <th scope="col">Vehicle Modal</th>
                      <th scope="col">Per KM Fare</th>
                      <th>Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {dataList?.data?.map((item, index) => {
                      return (
                        <tr key={item._id}>
                          <td>{(currentPage - 1) * perPage + index + 1}</td>
                          <td>{item?.state_id?.name}</td>
                          <td>{item?.city_id?.name}</td>
                          <td>{item?.name}</td>
                          <td>{item?.baseKm}</td>
                          <td>{item?.baseKmFare}</td>
                          <td>{item?.vehicleType}</td>
                          <td>{item?.perKmFare}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow "
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    handleDel(item._id);
                                  }}
                                  className="text-danger"
                                >
                                  <i className="fas fa-trash"></i> Delete
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => handleEdit(item?._id)}
                                  className="text-warning"
                                >
                                  <i class="fa-regular fa-pen-to-square"></i>{" "}
                                  Edit
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <TablePagination
                    totalPages={parseInt(dataList?.meta?.total_page)}
                    currentPage={parseInt(dataList?.meta?.current_page)}
                    pageUrl="/admin/faremanagement?"
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        )}
        <AddModal
          modal={modal}
          Name="Add6"
          toggle={toggleModal}
          title="Add Fare management"
          handleChangeData={handleChangeFormdata}
          formData={formdata}
          handleSubmit={handleSubmit}
          stateData={dataStates}
          cityData={dataCity}
        />
        <EditModal
          modal={editModal}
          Name="Edit1"
          toggle={toggleEditModal}
          title="Edit Fare management"
          handleChangeData={handleChangeEditFormdata}
          formEditData={formEditdata}
          handleSubmit={handleEditSubmit}
          stateData={dataStates}
          cityData={dataCity}
        />

        <Modal isOpen={modal1} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>
            <h4> Add Fare management</h4>
          </ModalHeader>
          <ModalBody>
         

            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">State</Label>
                    <Input
                      name="state_id"
                      type="select"
                      placeholder="Select State"
                      value={formdatanew?.state_id}
                      onChange={handleChangeFormdata}
                    >
                      <option value="">Select State</option>
                      {dataStates?.data?.map((ele) => (
                        <option value={ele?._id}>{ele?.name}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">City</Label>
                    <Input
                      name="city_id"
                      type="select"
                      placeholder="Select City"
                      value={formdatanew?.city_id}
                      onChange={handleChangeFormdata}
                    >
                      <option value="">Select City</option>
                      {dataCity?.data?.map((ele) => (
                        <option value={ele?._id}>{ele?.name}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Vehicle Type</Label>
                    <Input
                      name="vehicleType"
                      type="select"
                      value={vehicleType}
                      onChange={handleVehicleTypeChange}
                    >
                      <option value="">Select Vehicle Type</option>
                      <option value="citycab">City Cab</option>
                      <option value="minitruck">Mini Truck</option>
                      <option value="borewell">Borwell</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              {vehicleType === "citycab" || vehicleType === "minitruck" ? (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Fare Name</Label>
                        <Input
                          id="contact"
                          name="name"
                          type="text"
                          placeholder=" Enter Fare Name"
                          value={formdatanew?.name}
                          onChange={handleChangeFormdata}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Base KM</Label>
                        <Input
                          id="status"
                          name="baseKm"
                          type="number"
                          placeholder=" Enter Base Kilometer"
                          value={formdatanew?.baseKm}
                          onChange={handleChangeFormdata}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Base KM Fare</Label>
                        <Input
                          id="contact"
                          name="baseKmFare"
                          type="number"
                          placeholder=" Enter Base KM Fare"
                          value={formdatanew?.baseKmFare}
                          onChange={handleChangeFormdata}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Per KM FARE</Label>
                        <Input
                          id="contact"
                          name="perKmFare"
                          type="number"
                          placeholder=" Enter Fare Per Kilometer"
                          value={formdatanew?.perKmFare}
                          onChange={handleChangeFormdata}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}

              {vehicleType === "borewell" ? (
                <>
                  {formdatanew?.borewellFareData?.map((drill, index) => (
                    <Row key={index}>
                      <Col md={6}>
                        <FormGroup>
                          <Label for={`drillRange-${index}`}>
                            Select Range of Drill
                          </Label>
                          <Input
                            id={`drillRange-${index}`}
                            name="drillRange"
                            type="text"
                            placeholder="Enter Drill Range"
                            value={drill.drillRange}
                            onChange={(e) => handleDrillRangeChange(index, e)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for={`baseRate-${index}`}>Base Rate</Label>
                          <Input
                            id={`baseRate-${index}`}
                            name="baseRate"
                            type="text"
                            placeholder="Enter Base Rate"
                            value={drill.baseRate}
                            onChange={(e) => handleDrillRangeChange(index, e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ))}
                  <Button type="button" onClick={addDrillRange}>
                    Add More
                  </Button>
                </>
              ) : null}

              <Button type="submit">Submit</Button>
            </form>
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default FareManagement;
