import React, { useState } from "react";
import { Table, Collapse ,Input} from 'reactstrap';


const label = { inputProps: { "aria-label": "Checkbox demo" } };

const SubAdminPermission = () => {
  const rows = [
    { name: "Drivers", id: "1" },
    { name: "Rides", id: "2" },
    { name: "Users", id: "3" },
  ];

  const [checkedStates, setCheckedStates] = useState(
    rows.map((row) => ({
      name: row.name,
      view: false,
      add: false,
      update: false,
      delete: false,
    }))
  );

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectAll = (type) => {
    const newStates = checkedStates.map((state) => ({
      ...state,
      [type]: !state[type],
    }));
    setCheckedStates(newStates);

    if (selectedItems.includes(type)) {
      setSelectedItems((prev) => prev.filter((item) => item !== type));
    } else {
      setSelectedItems((prev) => [...prev, type]);
    }
  };

  const handleCheckboxChange = (name, type) => {
    const newStates = [...checkedStates];
    const index = newStates.findIndex((state) => state.name === name);
    newStates[index][type] = !newStates[index][type];
    setCheckedStates(newStates);

    if (selectedItems.includes(name)) {
      setSelectedItems((prev) => prev.filter((item) => item !== name));
    } else {
      setSelectedItems((prev) => [...prev, name]);
    }
  };

  const tablehedingStyle = {
    cursor: "pointer",
  
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="box-container" style={{ border: "1px solid #f0f1f3", width: "100%", height: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", height: "50px", marginBottom: "1rem", alignItems: "center" }} onClick={toggleCollapse}>
          <div style={{ marginLeft: "1.5rem" }}>
            <span>Permission</span>
          </div>
          <div style={{ marginRight: "1rem" }}>
            {isExpanded ? (
                <i class="fa-solid fa-caret-down" onClick={toggleCollapse}></i>
           
            ) : (
             
              <i class="fa-solid fa-caret-up" onClick={toggleCollapse}></i>
            )}
          </div>
        </div>

        <Collapse isOpen={isExpanded} style={{ padding:  0 }}>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Permission Type</th>
                <th onClick={() => handleSelectAll("view")} style={tablehedingStyle}>View</th>
                <th onClick={() => handleSelectAll("add")} style={tablehedingStyle}>Add</th>
                <th onClick={() => handleSelectAll("update")} style={tablehedingStyle}>Update</th>
                <th onClick={() => handleSelectAll("delete")} style={tablehedingStyle}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={row.id}>
                  <td>{row.name}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedStates[rowIndex].view}
                      onChange={() => handleCheckboxChange(row.name, "view")}
                      {...label.inputProps}
                 
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedStates[rowIndex].add}
                      onChange={() => handleCheckboxChange(row.name, "add")}
                      {...label.inputProps}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedStates[rowIndex].update}
                      onChange={() => handleCheckboxChange(row.name, "update")}
                      {...label.inputProps}
                    />
                  </td>
                  <td>
                    <input
                     type="checkbox"
                      checked={checkedStates[rowIndex].delete}
                      onChange={() => handleCheckboxChange(row.name, "delete")}
                      {...label.inputProps}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Collapse>
      </div>
    </div>
  );
};

export default SubAdminPermission;
