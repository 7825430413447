import { helper } from "lib/helper";
import usersModel from "models/users.model";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  FormGroup,
  Input,
} from "reactstrap";
// core components
import TablePagination from "views/components/TablePagination";

const DriverLists = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }


  const [selectedDriver, setSelectedDriver] = useState('');

  const handleSelectChange = (e) => {
    setSelectedDriver(e.target.value);
  };
  
  const getListData = async () => {
    setIsLoading(true);
    await usersModel.driverlist(selectedDriver).then((result) => {
      setIsLoading(false)
      //console.log(result)
      if (result) {
        setDataList(result);
        setCurrentPage(result?.meta?.current_page);
        setPerPage(result?.meta?.per_page);
      }
    }).catch((error) => {
      helper.sweetalert.toast(error.response?.data?.message, "warning")
    });
  }

  const handleDel = async (delId) => {
    helper.sweetalert
      .confirm("Are you sure you want to delete this Message", "info", true)
      .then((result) => {
        if (result.isConfirmed) {
          usersModel.delete(delId).then((res) => {
            helper.sweetalert.toast(res.data?.message);
            getListData();
          });
        }
      });
  };

  useEffect(() => {
    getListData();
  }, [location,selectedDriver])

  return (
    <>
      <Container className="pt-7" >
        {!isLoading && (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex align-items-center">
                
                 <div> <h3 className="mb-0">Drivers Lists</h3></div>
                 <div className="ml-5">
      <FormGroup className="mb-0">
        <Input
          name="status"
          type="select"
          placeholder="Select status"
          value={selectedDriver}
          onChange={handleSelectChange}
        >
          <option value="">Select Driver</option>
          <option value="carpool">City Cab</option>
          <option value="lorry">Mini Truck</option>
          <option value="borewell">Borewell</option>
        </Input>
      </FormGroup>
    </div>
                  
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact No.</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created On</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {dataList?.data?.map((item, index) => {
                      let currentDate = new Date(item.createdAt);
                      let formattedDate = currentDate.toISOString().substring(0, 10);
                      return (
                        <tr key={item._id}>
                          <td>{((currentPage-1) * perPage) + index + 1}</td>
                          <td>{item?.name}</td>
                          <td>{item?.email}</td>
                          <td>{item?.contact_no}</td>
                          <td>
                            {item?.status === "active" ? (
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-success" />
                                Active
                              </Badge>
                            ) : (
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                                {helper.capitalizeLetter(item?.status)}
                              </Badge>
                            )}
                          </td>
                          <td>{formattedDate}</td>
                          <td>
                            <UncontrolledDropdown key={`dropdown${item._id}`}>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow " right>
                                <DropdownItem
                                  onClick={(e) => navigate(`/admin/drivers/documents/${item?._id}`)}
                                  className="text-primary"
                                >
                                  <i className="fas fa-file-alt"></i> Documents
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) =>
                                    navigate(
                                      `/admin/user/drivers/vehicle?driver_id=${item?._id}`
                                    )
                                  }
                                  className="text-success"
                                >
                                  <i className="fas fa-taxi"></i> Vehicle
                                </DropdownItem>
                                <DropdownItem
                                     onClick={() => {handleDel(item._id)}}
                                  className="text-danger"
                                >
                                  <i className="fas fa-trash"></i> Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <TablePagination
                    totalPages={parseInt(dataList?.meta?.total_page)}
                    currentPage={parseInt(dataList?.meta?.current_page)}
                    pageUrl = "/admin/drivers?"
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        )}

      </Container>
    </>
  );
};

export default DriverLists;
